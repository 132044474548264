import { ClientSector } from "./Client"
import { ModelRelation } from "./utils"

type Quiz = {
    id: string;
    name: string;
    description: string;
    questions: Question[];
};

type Question = {
    id: string;
    category: FunctionalRequirementCategory;
    text?: string;
    type: QuestionType;
    title: string;
    justification?: string;
    requiresJustification?: boolean;
    description: string;
    results: Result[];
    totalValue: number;
    affectsScore?: boolean;
    dependsOn?: string;
    condition?: ComplianceResult;
}

type Result = {
    result: ComplianceResult;
    text: string;
    comment: string;
    value: number;
};

enum FunctionalRequirementCategory {
    Context = "Situation Questions",
    Security = "Security Requirement",
    Vulnerability = "Vulnerability Management Requirement",
    FDA = "FDA Cybersecurity Requirement",
}

enum ComplianceResult {
    NonCompliant = "Non-Compliant",
    RequiredImprovements = "Required Improvements",
    PartiallyCompliant = "Partially Compliant",
    FullyCompliant = "Fully Compliant",
    NoDocument = "No Document Available", // Nuevo valor añadido
    PartialDocument = "Partial Document Available", // Nuevo valor añadido
    FullDocument = "Full Document Available", // Nuevo valor añadido
    NotApplicable = "Not Applicable", // Nuevo valor añadido
    Yes = "Yes", // Para preguntas con opción de sí/no
    No = "No", // Para preguntas con opción de sí/no
    NoKnown = "I Dont Know"
}
enum QuestionType {
    SingleSelect = "Single Select",
    MultipleSelect = "Multiple Select",
    Boolean = "Boolean",
    Text = "Text",
}

enum QuizzType {
    CRA = "CRA",
    FDA = "FDA",
}

enum QuizzStatus {
    Pending = "Pending answers",
    Finished = "Finished quiz",
}

type Compliance = {
    id: string;
    registry: RegistryData[];
    conclusion: string;
    justification: {
        [key: string]: string;
    };

    type: QuizzType;
    client_id: string;
    updated_at: string;
    created_at: string;
    client: ModelRelation;
    score: number;
    conclusions: QuizConclusions
};
type QuizConclusions = {
    [key: string]: string;
}
type RegistryData = {
    time: string;
    [key: string]: ComplianceResult | string; // Clave dinámica para preguntas
};

const getComplianceTypeByClientSector = (sector: ClientSector): QuizzType[] => {
    if (ClientSector.HealthCare === sector) {
        return [QuizzType.CRA, QuizzType.FDA]
    }
    return [QuizzType.CRA]
}

export {
    FunctionalRequirementCategory,
    ComplianceResult,
    QuestionType,
    QuizzStatus,
    QuizzType,
    getComplianceTypeByClientSector
}
export type { Quiz, Question, Result, Compliance, RegistryData }
export default Compliance
