import React, { useContext, useState } from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography,
    useTheme
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import { StyledTextarea } from "@components/common/textareas"
import { ClientSelector } from "@components/client/index"
import ServicesContext from "@context/ServicesContext"
import { FilterOperation } from "@utils/queryParams"
import { QuizzType, RegistryData } from "@models/Compliance"
import { Can } from "@components/permissions"
import { I18nContext } from "I18nProvider"

interface Props {
  open: boolean;
  initialText: string;
  title: string;
  handleCloseDialog: () => void;
  handleConfirmDialog: (text: string) => void;
}

const initRegistry: RegistryData = Object.fromEntries(
    ["Time", "q1", "q2", "q3", "q4", "q5", "q6", "q7", "q8", "q9", "q10", "q11", "q12"].map(key => [key, ""])
) as RegistryData
const initValue = {
    id: "",
    registry: [initRegistry],
    conclusion: "",
    client_id: "",
    type: QuizzType.CRA,
    created_at: "",
    updated_at: "",
    justification: { },
    score: 0,
    client: { id: "" },
    conclusions: {}
}
const TextareaDialog: React.FC<Props> = ({
    open,
    initialText,
    title,
    handleCloseDialog,
    handleConfirmDialog
}) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const [text, setText] = useState(initialText)
    const complianceService = useContext(ServicesContext).complianceService
    const [error, setError] = React.useState<Error | null>(null)
    const [compliance, setCompliance] = React.useState(initValue)
    const [isClientSelected, setIsClientSelected] = useState(false)

    const handleSaveChanges = async () => {
        try {
            const response = await complianceService.update(compliance.id, compliance)
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
            }
        }
    }

    const handleClientSelect = (id: string) => {
        getComplianceDetail(id || "")
        setIsClientSelected(!!id)
    }

    const getComplianceDetail = async (id: string) => {
        if (id === "") {
            setCompliance(initValue)
            setText("")
            return
        }
        try {
            const response = await complianceService.getAll({ filters: [{ field: "client_id", operation: FilterOperation.EnumEqual, value: id }] })
            if (response.count < 1) {
                setCompliance(initValue)
                setText("")
                return
            }
            setCompliance(response.list[0])
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
            }
        }
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value)
    }
    const handleChange = (event: any) => {
        const { name, value, type, checked } = event.target
        let newValue = type === "checkbox" ? checked : value
        if (name === "emails") {
            newValue = newValue.split(/;/).map((email:any) => email.trim())
        } else if (name === "notification_score_flag") {
            newValue = (value.match(/\d+/) ? parseInt(value.match(/\d+/)[0], 10) : 0)
        }
    }

    const handleConfirm = () => {
        handleConfirmDialog(text)
        handleSaveChanges()
        handleCloseDialog()
    }

    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            disableEscapeKeyDown={true}

        >
            <DialogTitle>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Can key="ownership" I="ownership" an="Compliance" >
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={12}>
                            <ClientSelector value={compliance.client_id} onSelect={(id) => handleClientSelect(id || "")}></ClientSelector>
                        </Grid>
                    </Grid>
                </Can>
                {/* <StyledTextarea minRows={4} name="conclusion" placeholder="Enter conclusions here..." style={{ width: "100%", boxSizing: "border-box" }} value={text} onChange={handleTextChange}/> */}
                <StyledTextarea minRows={3} name="conclusion" value={text} onChange={handleTextChange} placeholder="Enter conclusions here..." style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={<CloseIcon />}
                    color="error"
                    sx={{ ml: "10px", color: theme.palette.secondary.contrastText }}
                    onClick={handleCloseDialog}
                >
                    {context.t.translate("CRA_cancel")}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    color="primary"
                    disabled={!isClientSelected}
                    sx={{ ml: "10px", color: theme.palette.secondary.contrastText }}
                    onClick={handleConfirm}
                >
                    {context.t.translate("CRA_save")}

                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TextareaDialog
