import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Track from "@models/Track"
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react"

interface TrackContextType {
    track: (data: any) => void;
    trackData: any[];
    interval: number;
    nextExecutionTime: number | null;
}

const TrackContext = createContext<TrackContextType | undefined>(undefined)

const TRACK_DATA_KEY = "trackData"
const LAST_EXECUTION_KEY = "lastExecutionTime"

interface TrackProviderProps {
    children: ReactNode;
    interval: number;
}

export const TrackProvider: React.FC<TrackProviderProps> = ({ children, interval }) => {
    const ability = useContext(AbilityContext)
    const trackService = useContext(ServicesContext).trackService
    const [trackData, setTrackData] = useState<any[]>(() => {
        const storedTrackData = localStorage.getItem(TRACK_DATA_KEY)
        return storedTrackData ? JSON.parse(storedTrackData) : []
    })

    const [nextExecutionTime, setNextExecutionTime] = useState<number | null>(() => {
        const lastExecutionTime = localStorage.getItem(LAST_EXECUTION_KEY)
        const now = new Date().getTime()
        if (lastExecutionTime) {
            const elapsedTime = now - JSON.parse(lastExecutionTime)
            if (elapsedTime < interval) {
                return now + interval - elapsedTime
            }
        }
        return now + interval
    })

    const trackDataRef = useRef(trackData)

    useEffect(() => {
        localStorage.setItem(TRACK_DATA_KEY, JSON.stringify(trackData))
        trackDataRef.current = trackData
    }, [trackData])

    useEffect(() => {
        localStorage.setItem(LAST_EXECUTION_KEY, JSON.stringify(new Date().getTime()))

        const executeTask = async () => {
            // TODO: Implementar la lógica de trackeo
            if (trackDataRef.current.length > 0 && !ability.can("*", "Client")) {
                try {
                    const newTrack: Track = {
                        id: "",
                        invoke: "web_track",
                        registry: trackDataRef.current,
                        client: { id: "00000000-0000-0000-0000-000000000000" }
                    }
                    await trackService.create(newTrack)
                    setTrackData([])
                } catch (error) {
                    console.error("Error al enviar los datos de trackeo:", error)
                }
            }
            setTrackData([])
            localStorage.setItem(LAST_EXECUTION_KEY, JSON.stringify(new Date().getTime()))
            setNextExecutionTime(new Date().getTime() + interval)
        }

        const lastExecutionTime = localStorage.getItem(LAST_EXECUTION_KEY)
        const now = new Date().getTime()
        let timeToNextExecution = interval

        if (lastExecutionTime) {
            const elapsedTime = now - JSON.parse(lastExecutionTime)
            if (elapsedTime < interval) {
                timeToNextExecution = interval - elapsedTime
            } else {
                executeTask()
            }
        } else {
            localStorage.setItem(LAST_EXECUTION_KEY, JSON.stringify(now))
        }

        const timeoutId = setTimeout(() => {
            executeTask()
            setInterval(executeTask, interval)
        }, timeToNextExecution)

        return () => clearTimeout(timeoutId)
    }, [interval])

    const track = (data: any) => {
        const newTrackData = [...trackData, { ...data, time: new Date() }]
        setTrackData(newTrackData)
    }

    return (
        <TrackContext.Provider value={{ track, trackData, interval, nextExecutionTime }}>
            {children}
        </TrackContext.Provider>
    )
}

export const useTrack = () => {
    const context = useContext(TrackContext)
    if (!context) {
        throw new Error("useTrack debe ser usado dentro de un TrackProvider")
    }
    return context
}
